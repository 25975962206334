.text-container {
    max-height: 100vh;
    /* height:90vh; */
    /* border: 1px solid black; */
    width: 100%;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    gap: 10px;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .tool--wrapper{
    min-height: 5vh;
    /* border: 1px solid black; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* flex-wrap: wrap; */
    gap: 7px;
  }
 .tool--wrapper .text--tool{
    height:5vh;
    width:100%;
    display:flex;
    /* flex-direction: ; */
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #E8E8E8 !important;
    border-radius: 10px;
    padding:0 5px;
    /* border:1px solid gray; */
  }
.text--tool .buttons{
    height: 100%;
          width: 100%;
          background:var(--primary-forecolor);
          border-radius: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
}

.text-component-container{
    height: 100dvh;
    width:100%;
    /* border:1px solid red; */
    display: flex;
    flex-direction: column;
}
.text-fields{
    height:5dvh;
    width:100%;
    margin:10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border:1px solid black; */
}
.add-text-btn{
    width:200px;
}
.text-tool-container{
    height:5dvh;
    width:70%;
    display: flex;
    gap:5px;
}
.text-tool-container .text-tool{
    height:40px;
    width:40px;
    /* border:1px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .text-tool button{
    height:100%;
    width:100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
} */
.text-align-tools{
    min-height:20vh;
    width:15vw;
    border:1px solid black;
    padding:5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:2px;
}
.text-align-tools .text-inner-align-tools{
    min-height:5vh;
    width:15vw;
    /* border:1px solid black; */
    /* padding:5px; */
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    gap:2px;
}