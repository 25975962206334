#canvas{
    border-radius: 5px;
    /* border:3px solid green; */
    /* height: 100%;
    width:100%; */
}
.editor-container{
    min-height: 100vh;
    max-width: 100vw;
    /* border:5px solid black; */
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

.editor-header{
    min-height:5vh;
    width:100vw;
    position: sticky !important;
    top: 0 !important;
    /* border:1px solid green; */
    display: flex;
    flex:1;
}
.header-left{
    height:5vh;
    width:15%;
    margin:0 20px;
    /* border:1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:5px;
}
.header-right{
    height:100%;
    width:85%;
    /* border:1px solid black; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.editor-main-body{
    min-height: calc(100vh - 5vh);
    width: 100vw;
    /* border:1px solid red; */
    display: flex;
}

.editor-main-body .editor-side-bar{
    min-height: calc(100vh - 5vh);
    width:25dvw;
    /* border:1px solid yellow; */
    display: flex;
}
.editor-side-bar .tabs{
    height:95vh;
    width:5dvw;
    border:1px solid rgba(0, 0, 0, .5);
    display: flex;
    flex-direction: column;
}
.editor-side-bar .tabs-panel{
    height:95vh;
    width:20dvw;
    border-top:1px solid rgba(0, 0, 0, .5);
    display: flex;
    flex-direction: column;
}
.editor-side-bar .tabs .tab-menu{
    height:8dvh;
    width:100%;
    /* border:1px solid black; */
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.editor-main-body .editor-body{
    padding: 5px;
    min-height: 95vh;
    /* height:100%; */
    min-width:calc(100dvw - 25dvw);
    border:6px solid #DDDDDD;
    display: flex !important;
    flex: 1 !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    background-color: #DDDDDD;
    overflow-y: auto;
    overflow-wrap: break-word;
}

.canvas-prop-container{
    width:70%;
    /* border:1px solid palevioletred; */
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
}
.canvas-prop-container .fields-prop{
    height:5vh;
    min-width:20dwh;
    padding: 0 5px;
    /* border:1px solid black; */
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
}
.canvas-prop-container button{
    border:none;
    outline: none;
    background-color: transparent;
    /* outlet:none; */
}
.seprator-vertical{
    height:4vh;
    width:1px;
    background-color: gray;
}
.fields-prop input{
    border:1px solid black;
    outline: none;
    height: 30px;
    width: 100px;
}
.fields-prop .canva-size-button{
height:40px;
    background-color: white;
border:1px solid black;
text-transform: capitalize;
color:black;
width:150px;
padding:2px;
border-radius: 20px;
}
.fields-prop .canva-save-button{
    height:40px;
        background-color: var(--primary-color);
    border:1px solid black;
    text-transform: capitalize;
    color:white;
    width:150px;
    padding:2px;
    border-radius: 20px;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    }
.pop-over{
    height:5vh;
    min-width:30dwh;
   /* border:1px solid black; */
   padding: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 5px;
}
.pop-over input{
    border:1px solid black;
    outline: none;
    height: 30px;
    width: 100px;
}
.pop-over .canva-confirm-size-button{
    /* background-color: white; */
    /* border:1px solid black; */
    text-transform: capitalize;
    cursor: pointer;
}
