.status-badge {
    height: 20px;
    width: 20px;
    border: 2px solid whitesmoke;
    border-radius: 50%;
    margin: 0 auto;
}

.success {
    background-color: green;
}

.danger {
    background-color: red;
}
.license-waiting{
  background-color: orange;
}
.license-expires{
  background-color: yellow;
}
.screen-table {
  margin: 35px auto;
    /* width: 100%; */
}

.button-controlls {
    height: 100%;
    width: 100%;
    /* border:1px solid black; */
}

.add-screen-btn {
    height: 50px;
    width: 100%;
    background-color: var(--primary-color);
    color: white;
    border: none;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
}


.btn-1 {
    height: 50px;
    width: 50%;
    margin: 5px 5px 0 0;
    background-color: white;
    border: 1px solid var(--primary-color) !important;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
}

.btn-2 {
    height: 50px;
    width: 50%;
    margin: 5px 0 0 0;
    background-color: white;
    border: 1px solid var(--primary-color) !important;
    outline: none;
    border-radius: 6px;
    cursor: pointer;
}
@media  (max-width:600px){
    .add-screen-btn{
      width:200px !important;
      height:30px !important;
      /* margin: 0 20px; */
    }
    .button-controlls{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .lower-btn-group{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn-1,.btn-2{
      height:30px;
      width:42%;
    }
  }

/* .screen-form{
    height:35vh;
    width:30vw;
} */
.icon-screen {
    color: var(--primary-color);
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-screen .screen-icon {
    height: 100%;
    width: 100%;
}
.control-area{
    margin: 0 25px;
    display: flex;
    width: 100%;
    border-radius: 16px;
     /* padding: 20px; */
    align-items: center;
  }
  
  @media only screen and (max-width:768px) {
    .control-area{
      display: flex;
      flex-direction: column;
    }
  }

  .btn-cancel-screen{
    background: white;
    padding: 8px;
    width: 130px;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    margin-right: 10px;
    border-radius: 5px;
  }
  .btn-save-screen{
    background: var(--primary-color);
                  padding: 8px;
                  width: 130px;
                  color: white;
                  border: none;
                  border-radius: 5px;
  }
  @media (max-width:600px){
    .btn-cancel-screen,.btn-save-screen{
        width:90px !important;
        height:30px !important;
    }
  }