.date-container {
  max-height: 100vh;
  /* height:90vh; */
  /* border: 1px solid black; */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  gap: 10px;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  }
  .tool--wrapper{
    min-height: 5vh;
    /* border: 1px solid black; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* flex-wrap: wrap; */
    gap: 7px;
  }
  .tool--wrapper .text--tool{
    height:5vh;
    width:100%;
    display:flex;
    /* flex-direction: ; */
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #E8E8E8 !important;
    border-radius: 10px;
    padding:0 5px;
    /* border:1px solid gray; */
  }
  .text--tool .buttons{
    height: 100%;
          width: 100%;
          background:var(--primary-forecolor);
          border-radius: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
  }