.folder{
    cursor:pointer;
    height: 70px;
    width: 150px;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
    background:var(--primary-color);
    color:white;
    transition: all .5s ease-in;
    position: relative;
    z-index: 1;
}
.menu-icon{
  height:25px;
  width:20px;
  background: rgba(255,255,255,0.8);
  position: absolute;
  top:0;
  right:0;
  margin:3px 3px;
  z-index: 1000;
  border-radius: 5px;
  display:flex;
  justify-content: center;
  align-items: center;
}
.icon-property{
  color:black;
  background-color: white;
  padding: .5px;
  border-radius: 5px;
  background: rgba(255,255,255,0.8);
  position: absolute;
  top:0;
  right:0;
  margin:3px 3px;
  z-index: 1000;
}
/* .folder:hover{
  box-shadow: 1px 1px 3px 2px rgba(0,0,0,.2),
  -1px -1px 3px 2px rgba(0,0,0,.2);
} */