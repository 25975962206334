.layer-container{
    height: 100dvb;
    width:100%;
    display: flex;
    flex-direction: column;
}
.layers{
    height: 5dvh;
    width:90%;
    margin:10px auto;
    /* border:1px solid black; */
    border-radius: 5px;
    box-shadow: 2px 2px 5 5 rgba(0, 0,0,0.5),
    -2px -2px 5 5 rgba(0, 0,0,0.5)
    ;
    background-color: #D7DADE;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.layers img{
    max-width: 90%;
    max-height: 3dvb;
    margin:0 10px;
}

.main-template-container{
    min-height: 10vh;
    /* border: 1px solid black; */
    width: 100%;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    gap: 10px;
    padding: 10px 10px;
}
.template-container{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center;
    align-items: center; */
    max-height:80vh !important;
    /* max-height: 100vh; */
    width:100%;
    gap:6px;
    overflow-y: auto;
    /* border:1px solid black; */
}
.template-divs{
    height:170px;
    width:160px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.1), -2px -2px 5px 1px rgba(0,0,0,0.1);
    border-radius: 5px;
    cursor: pointer;
}
.template-divs img{
    height:80%;
    width:100%;
}
.template-divs span{
height:20%;
width:100%;
display: flex;
justify-content: center;
align-items: center;
}