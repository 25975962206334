.main-controll-box {
  height: 15vh;
  width: 100%;
  /* border:1px solid yellow; */
  display: flex;
}

.main-controll-box .main-controll-box-left {
  width: 70%;
  height: 100%;
  /* border-right: 1px solid black; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
  border-radius: 20px;
}
.user-add-btn {
  width: 280px !important;
  height: 58px;
  /* border:1px solid black; */
  margin: 0 5px !important;
  /* border-radius: 30px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
@media  (max-width:600px){
  .user-add-btn button{
    width:200px !important;
    height:30px !important;
    /* margin: 0 20px; */
  }
}
.user-add-btn button {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  background-color: var(--primary-color) !important;
  border-radius: 30px !important;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-add-btn .content-folder-btn{
  height: 100%;
  width: 100%;
  border: 1px solid var(--primary-color) !important;
  outline: none;
  background-color: white !important;
  border-radius: 6px;
  color:var(--primary-color) !important ;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-controll-box-left .search-box {
  display: flex;
}

.search-box .search-icon {
  background: rgba(86, 31, 255, 0.1);
  /* opacity: 0.1; */
  border-radius: 16px;
  width: 63px;
  height: 63px;
  margin: 0 35px 0 10px;
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-box .search-input-field {
  margin: 0 -15px;
  width: 80%;
}

.main-controll-box .main-controll-box-right {
  width: 30%;
  height: 100%;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-controll-box-right .content-input-controll-field {
  height: 60px;
  /* width: 80%;
  margin: 0 auto; */
  /* border:1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-controll-box-right .content-input-controll-field .add-content-btn {
  background: var(--primary-color);
  border-radius: 6px;
  border: none;
  height: 40px;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-checkbox {
  width: 20%;
  height: 40px;
  /* border:1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-all-delete-btn {
  width: 100%;
  height: 40px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-all-delete-btn button {
  height: 40px;
  width: 100%;
  background: #ffffff;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  color: var(--primary-color);
}

.main-controll-box-right {
  padding-left: 30px;
  padding-right: 10px;
}

.content-render {
  display: flex !important;
  flex-wrap: wrap !important;
  height: 100%;
  width:100%;
  margin: 10px auto;
  position: relative;
}

.content-item {
  width:230px;
  min-width: 24%;
  height: 230px;
  margin-right: 1%;
  margin-bottom: 1%;
  background: #ffffff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
}

.content-item .content-image {
  height: 80%;
  width: 100%;
  position: relative;
}
 .pdf-viewer{
  width:100% !important;
  /* min-width: 24% !important; */
  height: 80% !important;
}
.content-item .content-image .content-del-icon {
  position: absolute;
  background: #ffffff;
  opacity: 0.9;
  border-radius: 10px;
  height: 40px;
  width: 40px;
  right: 18px;
  bottom: 131px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
}

.content-image img,
.content-image video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.content-bottom {
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-bottom p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding: 15px;
}

.content-bottom p span {
  margin: 0 5px;
}

.content-add-media-box {
  margin: 20px 0;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.add-media-tab {
  height: 84px;
  width: 234px;
  background: #ffffff;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  display: flex;
  cursor: pointer;
  margin: 15px 10px;
}

.add-media-tab-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-media-label {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-media-label .add-media-icon,
.add-media-tab .add-media-icon {
  height: 56px;
  width: 56px;
  margin: 0 10px;
  cursor: pointer;
  color: var(--primary-color);
}
.add-media-icon {
  color:var(--primary-color);
}
.media-text {
  color: var(--primary-color);
}

.btn-save-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: var(--primary-color);
  padding: 10px 8px;
  width: 130px;
  color: white;
  border: none;
  border-radius: 5px;
}






.form-control {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  border-radius: 10px;
}

/* input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: red;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid red;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  border-radius: 10px;
} */

input[type="checkbox"] {
  /* ...existing styles */
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]::before {
  /* ...existing styles */

  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  
}

.control-area{
  background-color: white;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 95%;
}
@media only screen and (max-width:768px) {
  .control-area{
    display: flex;
    flex-direction: column;
    margin-left: 20px !important;
  }
}