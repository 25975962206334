.video-component-container{
    min-height: 10vh;
  /* border: 1px solid black; */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  gap: 10px;
  padding: 10px 10px;
}
.upslash-container{
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center;
    align-items: center; */
    max-height:80vh !important;
    /* max-height: 100vh; */
    width:100%;
    gap:6px;
    overflow-y: auto;
    /* border:1px solid black; */
}
.upslash-divs{
    height:170px;
    width:160px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.1), -2px -2px 5px 1px rgba(0,0,0,0.1);
    border-radius: 5px;
    cursor: pointer;
}
.upslash-divs img,.upslash-divs video{
    height:80%;
    width:100%;
}
.upslash-divs span{
height:20%;
width:100%;
display: flex;
justify-content: center;
align-items: center;
}