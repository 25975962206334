/* .table-background {
    background-color: #ffffff;
    border-radius: 24px;
} */

.table-user-con {
    margin: 35px auto;
}

.search-box {
    display: flex;
}

.search-box .search-icon {
    margin: 0 32px 0 20px !important;
}

.user-add-btn {
    width: 273px;
    height: 58px;
    /* border:1px solid black; */
    margin: 0 20px;
}

.dialogBorder {
    border-radius: 16px !important;
}

.user-add-btn button {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    background: var(--primary-color);
    border-radius: 6px;
    color: white;
    cursor: pointer;
}
@media  (max-width:600px){
    .user-add-btn button{
      width:200px !important;
      height:30px !important;
      /* margin: 0 20px; */
    }
  }

.user-add-form-container {
    width: 30vw;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media (max-width:600px){
    .user-add-form-container{
        width:70dvw;
        overflow: scroll;
    }
}
.user-add-form-container .form-fields {
    height: 30;
    width: 90%;
    /* border:1px solid black; */
    margin: 10px auto;
}

.add-user-form-submit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35px 0 0 0;
}

.add-user-form-submit-btn button {
    width: 215px;
    height: 40px;
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 6px;
    color: white;
    cursor: pointer;
}
@media (max-width:600px){
    .add-user-form-submit-btn button{
        width:150px;
        height:30px;
    }
}
.control-area{
    background-color: white;
    border-radius: 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }
  
  @media only screen and (max-width:768px) {
    .control-area{
      display: flex;
      flex-direction: column;
    }
  }